import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import ReactGA from 'react-ga4';

const root = ReactDOM.createRoot(document.getElementById('root'));

ReactGA.initialize('G-14HVY1P7F9');

root.render(
    <App />
);


reportWebVitals();
