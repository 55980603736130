import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import Main from "./components/Main";
import MadLibs from "./components/MadLibs";

import './App.css';

export default class App extends React.Component {
    render = () => {
        return (
            <BrowserRouter>
                <div className="App container-fluid">
                    <Header/>
                    <div className="row">
                        <div className="col-12 col-sm-6 mx-auto">
                            <Routes>
                                <Route key="home" path="/" element={
                                    <Main/>
                                }/>
                                <Route key="app" path="/app" element={
                                    <MadLibs/>    
                                }/>
                            </Routes>
                        </div>
                    </div>
                    
                    <Footer companyName={"Next Day Games"}/>
                </div>
            </BrowserRouter>
        )
    }
}
