import React from "react";

export default class WhyItMatters extends React.Component {
    render() {
        return (
            <div className="row">
                <div className="col-12 col-sm-6 mx-auto">
                    <h3>Bad Reviews come for Bad Pitches</h3>
                    <p>My favorite way to align game rules and players is to match what my game does with what meaning the player will get.</p>
                    <p>People leave negative reviews on games when you promise one thing, but deliver another. Discover how you might align your pitch and your game.</p>
                    <p>If you only choose one kind of meaning, your game might come out niche. Many kinds of meaning can give it mass appeal.</p>
                </div>
            </div>
        );
    }
}