import React from "react";
import "./Header.css"

type HeaderProps = {
    companyName: string
}

export default class Header extends React.Component<HeaderProps> {
    render() {
        return (
            <div className="header col-12 col-sm-6 mx-auto">
                <p className="title">🤔 DESCRIBE MY GAME</p>
            </div>
        );
    }
}