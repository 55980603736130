import React from "react";
import "./Footer.css"

type FooterProps = {
    companyName: string
}

export default class Footer extends React.Component<FooterProps> {
    render() {
        return (
            <div className="footer col-12">
                <p className="footer-logo">🤔</p>
                <p className="name-and-author">Describe my Game - An app by <a href="http://oliverbarnum.com/">Oliver Barnum</a></p>
                <p>{`Copyright ${this.props.companyName} © ${new Date().getFullYear().toString()} - All rights reserved`}</p>
            </div>
        );
    }
}