import React from "react";
import "./VerbChoice.css"
import SOURCES_OF_MEANING from "./SourcesOfMeaning";
const sortAlphabetically = (a, b) => {
    if (a < b) {
      return -1;
    }
    if (a > b) {
      return 1;
    }
    return 0;
}
export default class VerbChoice extends React.Component {     
    render() {        
        
        var sourceOfMeaningChoices = Object.keys(SOURCES_OF_MEANING).map(key => 
            <option key={key} value={key}>{SOURCES_OF_MEANING[key].emoji} {key}</option>
        )

        const verbs = SOURCES_OF_MEANING[this.props.sourceOfMeaning].verbs
        const sortedVerbs = verbs.sort(sortAlphabetically)
        var verbChoices = sortedVerbs.map(verb => 
            <option key={verb} value={verb}>{verb}</option>
        )
        return <div className="verb-choice">

            <div className="input-group">
                <select 
                    value={this.props.sourceOfMeaning} 
                    onChange={(event)=>this.props.updateSourceOfMeaningCallback(this.props.index, event.target.value)} 
                    className="form-select scope-select bg-dark text-white verb-select"
                >
                    {sourceOfMeaningChoices}
                </select>

                <select 
                    value={this.props.verb} 
                    onChange={(event)=>this.props.updateVerbCallback(this.props.index, event.target.value)} 
                    className="form-select scope-select bg-dark text-white verb-select"
                >
                    {verbChoices}
                </select>
                {this.props.deleteVerbChoiceCallback !== undefined &&
                    <button onClick={()=>this.props.deleteVerbChoiceCallback(this.props.index)} className="btn btn-outline-secondary verb-select" type="button" id="button-addon1">🗑️</button>
                }
            </div>
            <div className="input-group mb-3">
                <input 
                    type="text" 
                    className="form-control bg-dark text-white" 
                    placeholder="the Object" 
                    aria-label="Object" 
                    value={this.props.object} 
                    onChange={(event)=>this.props.updateObjectCallback(this.props.index, event.target.value)} 
                />
            </div>
      </div> 
    }
}