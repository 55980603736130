import React from "react";
import Splash from "./Main/Splash";
import AsSeenOn from "./Main/AsSeenOn";
import WhyItMatters from "./Main/WhyItMatters";
import Encouragement from "./Main/Encouragement";
import "./Main/Main.css"

export default class Main extends React.Component {
    render = () => {
        return (
            <React.Fragment>
                <Splash/>           
                <WhyItMatters/>    
                <AsSeenOn/>              
            </React.Fragment>
        )
    }
}
