import React from "react";
import DescribeMyGameButton from "./DescribeMyGameButton";

const sourcesOfMeaning = [
    { determination: "Relatedness", name: "Destruction", emoji: "💥" },
    { determination: "Relatedness",name: "Excitement", emoji: "😱" }, 
    { determination: "Relatedness",name: "Competition", emoji: "🤼" }, 
    { determination: "Relatedness",name: "Community", emoji: "🫂" }, 
    { determination: "Competence", name: "Challenge", emoji: "🥋" }, 
    { determination: "Competence",name: "Strategy", emoji: "🧠" }, 
    { determination: "Competence",name: "Collection", emoji: "🦋" }, 
    { determination: "Competence",name: "Power", emoji: "👑" }, 
    { determination: "Autonomy",name: "Design", emoji: "🎨" }, 
    { determination: "Autonomy",name: "Story", emoji: "📚" }, 
    { determination: "Autonomy",name: "Fantasy", emoji: "🧙" }, 
    { determination: "Autonomy",name: "Discovery", emoji: "🏞️" } 
]

export default class Splash extends React.Component {
    render() {
        const meaningSources = sourcesOfMeaning.map(meaning => 
            <span className="meaning-teaser" key={meaning.name}>{meaning.emoji} {meaning.name} </span>
        )
        return (
            <div className="row splash d-flex justify-content-center">
                <div className="col-12 col-sm-6">
                    <h1 className="pitch">Discover your Pitch</h1>
                    <p className="pitch-description">Nail the your game's pitch to set it apart from the competition with my free brainstorming tool!</p>
                    <DescribeMyGameButton/>
                </div>
                <div className="col-12 col-sm-6">
                    <h4>How it works:</h4>
                    <p>Pick a verb the player will do in game based on the meaning it provides.</p>
                    <p>{meaningSources}</p>
                    <p>Choose your game's name and explain the stakes.</p>
                    <p><em>Get your game pitch!</em></p>
                    
                </div>
            </div>
        );
    }
}