import React from "react";
import asSeenOn from "./asSeenOn_grayScale.svg"

export default class AsSeenOn extends React.Component {
    render() {
        return (
            <div className="row">
                <div className="col-12 col-sm-6 mx-auto">
                    <p className="as-seen">As seen on</p>
                    <img className="social-media-logos" src={asSeenOn} alt="Social media logos"/>
                </div>
                <div className="col-12 col-sm-6 mx-auto">
                    <h3>"I am not a real person. Skrrt skrrt SKRAAA."</h3>
                    <p>⭐⭐⭐⭐⭐ - Cody</p>
                </div>
            </div>
        );
    }
}