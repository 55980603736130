import React from "react";
import "./DescriptionApp/DescriptionApp.css"
import VerbChoice from "./DescriptionApp/VerbChoice";
import SOURCES_OF_MEANING from "./DescriptionApp/SourcesOfMeaning"
import { Link } from 'react-router-dom';

export default class MadLibs extends React.Component {
    state={
        verbChoices: [],
        gameName: "",
        stakes: "",
        numberPlayers: "4",
        gameObjective: undefined
    }

    componentDidMount() {
        var sourceOfMeaningOptions = Object.keys(SOURCES_OF_MEANING)
        const chosenMeaningKeyIndex = Math.floor(sourceOfMeaningOptions.length * Math.random())
        const chosenMeaning = SOURCES_OF_MEANING[sourceOfMeaningOptions[chosenMeaningKeyIndex]]
        this.setState({gameObjective: {
            sourceOfMeaning: chosenMeaning.name,
            verb: chosenMeaning.verbs[Math.floor(chosenMeaning.verbs.length * Math.random())],
            object: ""
        }})
    }

    updateNumberPlayers = (numberPlayers) => {
        this.setState({numberPlayers: numberPlayers})
    }

    updateObjectiveSourceOfMeaning = (sourceOfMeaning) => {
        var gameObjective = this.state.gameObjective
        gameObjective.sourceOfMeaning = sourceOfMeaning
        this.setState({
            gameObjective: gameObjective
        })
    }
    updateObjectiveVerb = (verb) => {
        var gameObjective = this.state.gameObjective
        gameObjective.verb = verb
        this.setState({
            gameObjective: gameObjective
        })
    }
    updateObjectiveObject = (object) => {
        var gameObjective = this.state.gameObjective
        gameObjective.object = object
        this.setState({
            gameObjective: gameObjective
        })
    }

    addVerbChoice = () => {
        var verbChoices = this.state.verbChoices
        var sourceOfMeaningOptions = Object.keys(SOURCES_OF_MEANING)
        const chosenMeaningKeyIndex = Math.floor(sourceOfMeaningOptions.length * Math.random())
        const chosenMeaning = SOURCES_OF_MEANING[sourceOfMeaningOptions[chosenMeaningKeyIndex]]
        
        verbChoices.push({
            sourceOfMeaning: chosenMeaning.name,
            verb: chosenMeaning.verbs[Math.floor(chosenMeaning.verbs.length * Math.random())],
            object: ""
        })
        this.setState({
            verbChoices: verbChoices
        })
    }
    updateSourceOfMeaningByIndex = (index, sourceOfMeaning) => {
        var verbChoices = this.state.verbChoices
        verbChoices[index].sourceOfMeaning = sourceOfMeaning
        verbChoices[index].verb = SOURCES_OF_MEANING[sourceOfMeaning].verbs[Math.floor(SOURCES_OF_MEANING[sourceOfMeaning].verbs.length * Math.random())]
        this.setState({
            verbChoices: verbChoices
        })
    }
    updateVerbByIndex = (index, verb) => {
        var verbChoices = this.state.verbChoices
        verbChoices[index].verb = verb
        this.setState({
            verbChoices: verbChoices
        })
    }
    updateObjectByIndex = (index, object) => {
        var verbChoices = this.state.verbChoices
        verbChoices[index].object = object
        this.setState({
            verbChoices: verbChoices
        })
    }
    deleteVerbChoice = (index) => {
        var verbChoices = this.state.verbChoices
        verbChoices.splice(index)
        this.setState({
            verbChoices: verbChoices
        })
    }
    static capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }
    static cleanupObject(string) {
        if (string[string.length-1] === ".")
            string = string.slice(0,-1);
        return string
    }
    updateGameName = (gameName) => {
        this.setState({gameName: gameName})
    }
    updateStakes = (stakes) => {
        this.setState({stakes: stakes})
    }
    copyResultToClipboard = (explanation) => {
        navigator.clipboard.writeText(`${explanation}\nMade with https://www.describemygame.com/`)
    }
    createGameExplanation = () => {
        var gameExplanation = ""
        if (this.state.gameName.trim() !== "") {
            gameExplanation = `${this.state.gameName} is game for ${this.state.numberPlayers} player(s)`
            if (this.state.gameObjective !== undefined && this.state.gameObjective.object.trim() !== "") {
                gameExplanation = gameExplanation + ` where you ${this.state.gameObjective.verb} ${MadLibs.cleanupObject(this.state.gameObjective.object)}. `
            }
            else {
                gameExplanation += ". "
            }
        }
        
        this.state.verbChoices.forEach((verbChoice, index) => {
            if (verbChoice.object.trim() === "" || verbChoice.verb === undefined) {
                return <span key={index}></span>    
            }
            var verbedObject = `${MadLibs.capitalizeFirstLetter(verbChoice.verb)} ${MadLibs.cleanupObject(verbChoice.object)}. `
            gameExplanation = gameExplanation + verbedObject
        })
        if (this.state.stakes.trim() !== "") {
            gameExplanation = gameExplanation + `${this.state.stakes}`
        }
        return gameExplanation
    }

    render = () => {
        var verbChoiceElements = this.state.verbChoices.map((verbChoice, index) =>
            <VerbChoice 
                key={`${index}`} 
                index={index} 
                sourceOfMeaning={verbChoice.sourceOfMeaning} 
                verb={verbChoice.verb} 
                object={verbChoice.object}
                updateSourceOfMeaningCallback={this.updateSourceOfMeaningByIndex}
                updateVerbCallback={this.updateVerbByIndex}
                updateObjectCallback={this.updateObjectByIndex}
                deleteVerbChoiceCallback={this.deleteVerbChoice}
            />    
        );
        var playerCountChoices = []
        for (let index = 1; index < 10; index++) {
            var number = index.toString()
            playerCountChoices.push(<option key={number} value={number}>{number}</option>)            
        }
        playerCountChoices.push(<option key={"10+"} value={"10+"}>{"10+"}</option>)

        var gameExplanation = this.createGameExplanation()
        var isGameObjectiveVisible = this.state.gameName.length > 4 && this.state.gameObjective !== undefined
        var areVerbsVisble = isGameObjectiveVisible && this.state.gameObjective.object.trim() !== ""
        var hasVerbWithObject = false
        for (let index = 0; index < this.state.verbChoices.length; index++) {
            const verbChoice = this.state.verbChoices[index];
            if (verbChoice.object.trim() !== "") {
                hasVerbWithObject = true
                break
            }
        }
        var areStakesVisible = hasVerbWithObject
        var isExplanationVisible = areStakesVisible && this.state.stakes.trim().length > 3
        return (
            <div className="row">
                <div className="col">
                    <div className="input-group mb-3">
                        <input 
                            type="text" 
                            className="form-control bg-dark text-white subtle-border" 
                            placeholder="Game Name" 
                            aria-label="Game Name" 
                            value={this.state.gameName} 
                            onChange={(event)=>this.updateGameName(event.target.value)} 
                        />
                        <span class="input-group-text bg-dark text-white subtle-border no-right-border" ># Players</span>
                        <select 
                            value={this.state.numberPlayers} 
                            onChange={(event)=>this.updateNumberPlayers(event.target.value)} 
                            className="form-select scope-select bg-dark text-white player-count-choice subtle-border  no-left-border"
                        >
                            {playerCountChoices}
                        </select>
                    </div>
                    {isGameObjectiveVisible &&
                        <div className="game-object-section">
                            <h4>Objective</h4>
                            <VerbChoice 
                                index={-1} 
                                sourceOfMeaning={this.state.gameObjective.sourceOfMeaning} 
                                verb={this.state.gameObjective.verb} 
                                object={this.state.gameObjective.object}
                                updateSourceOfMeaningCallback={(_, sourceOfMeaning)=> this.updateObjectiveSourceOfMeaning(sourceOfMeaning)}
                                updateVerbCallback={(_, verb)=> this.updateObjectiveVerb(verb)}
                                updateObjectCallback={(_, object)=> this.updateObjectiveObject(object)}
                            />
                        </div>
                    }
                    {areVerbsVisble && 
                        <div className="verb-section">
                            <h4>What do you get to do?</h4>
                            <div className="verb-choices">
                                {verbChoiceElements}
                                { verbChoiceElements.length === 0 &&
                                    <p>Add a Verb below...</p>
                                }
                            </div>
                            <button className="btn btn-outline-warning add-a-verb" onClick={this.addVerbChoice}>Add a Verb</button>
                        </div>
                    }
                    {areStakesVisible && 
                        <div className="input-group mb-3">
                            <input 
                                type="text" 
                                className="form-control bg-dark text-white subtle-border stakes" 
                                placeholder="What's at stake? 'Will you be able to xyz before abs...?'" 
                                aria-label="stakes" 
                                value={this.state.stakes} 
                                onChange={(event)=>this.updateStakes(event.target.value)} 
                            />
                        </div>
                    }
                    { isExplanationVisible && 
                        <>
                            <p>{gameExplanation}</p>
                            <button className="btn btn-outline-warning" onClick={()=> this.copyResultToClipboard(gameExplanation)}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-copy" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"/></svg> Copy
                            </button>
                            <div className="templative-explanation">
                                <h4>Ready to make your board game pitch a reality?</h4>
                                <p><span className="templative-callout">Templative</span> is a board game design app that helps you skip the tedious parts of making, printing, uploading, playtesting, and translating your board game.</p>
                                <Link to="https://www.templative.net" className="btn btn-primary describe-button">Skip the Tedium</Link >
                            </div>
                        </>
                    }
                            
                </div>
            </div>
        )
    }
}
