const SOURCES_OF_MEANING = {
    "Destruction": { determination: "Relatedness", name: "Destruction", emoji: "💥", verbs: [ "ravage", "devastate", "ruin", "plunder", "scourge", "obliterate", "eradicate", "overwhelm", "wreck", "shatter", "sack", "annihilate", "despoil", "demolish", "vaporize", "desolate", "extinguish", "wipe out", "extirpate", "decimate", "expunge", "strip", "pillage", "loot", "waste", "smash", "overthrow", "total", "mow", "harry", "raze", "nuke", "overpower", "overrun", "maraud", "wrack", "foray", "crush", "rub out" ] },
    "Excitement": { determination: "Relatedness",name: "Excitement", emoji: "😱", verbs: [ "push your luck", "thrill", "electrify", "delight", "inspire", "titillate", "galvanize", "arouse", "intoxicate", "intrigue", "exhilarate", "stimulate", "provoke", "enchant", "turn on", "incite", "captivate", "pump up", "charge", "interest", "mesmerize", "enthrall", "hypnotize", "tantalize", "rivet", "bewitch", "enthral", "charm", "spellbind" ] }, 
    "Competition": { determination: "Relatedness",name: "Competition", emoji: "🤼", verbs: [ "contend", "race", "fight", "battle", "rival", "vie", "play", "engage", "challenge", "face off", "work", "maneuver", "try out", "jostle", "jockey", "go out" ] }, 
    "Community": { determination: "Relatedness",name: "Community", emoji: "🫂", verbs: [ "collaborate", "unite", "join", "conspire", "concur", "concert", "play ball", "combine", "pull together", "team up", "band together", "make common cause", "conjoin", "affiliate", "connive", "interface", "ally", "confederate", "associate", "hang together" ] }, 
    "Challenge": { determination: "Competence", name: "Challenge", emoji: "🥋", verbs: [ "master", "defeat", "overcome", "worst", "beat", "best", "take", "conquer", "dispatch", "get", "stop", "subdue", "lick", "succeed", "win against", "surmount", "get the better of", "get around", "triumph over", "prevail over", "trim", "overbear", "upend", "do down", "overmatch", "finish", "skunk", "sweep", "bury", "excel", "surpass", "overwhelm", "whip", "better", "knock for a loop", "slaughter", "crush", "blow out", "score", "nose out", "blow away", "top", "exceed", "snow under", "annihilate", "rout", "cream", "clobber", "vanquish", "edge out", "skin", "bomb", "overthrow", "flourish", "upset", "subjugate", "pip", "thrash", "transcend", "break", "cap", "outdo", "drub", "overpower", "smoke", "trounce", "flatten", "knock over", "wax", "eclipse", "wallop", "outshine", "unseat", "shellac", "outstrip", "knock off", "outdistance", "ace out", "outfight", "overtop" ] }, 
    "Strategy": { determination: "Competence",name: "Strategy", emoji: "🧠", verbs: [ "prepare", "design", "organize", "arrange", "devise", "plot", "budget", "calculate", "blueprint", "chart", "outline", "project", "shape", "scheme out", "choreograph", "draft", "map out", "strategize about", "lay out", "intend", "frame", "contemplate", "figure", "contrive", "concert", "conspire", "sketch", "aim", "mean", "machinate", "have on", "get up", "intrigue", "meditate", "put up", "premeditate", "plan", ] }, 
    "Collection": { determination: "Competence",name: "Collection", emoji: "🦋", verbs: [ "finish", "perfect", "finalize", "consummate", "accomplish", "get through", "fulfill", "fulfil", "polish", "do", "execute", "achieve", "perform", "improve", "effect", "carry out", "better", "enhance", "stick out", "carry through", "follow through with", "refine", "amend", "enrich", "machine", "ameliorate", "shine", "discharge", "round off or out", "meliorate", "touch up", "accumulate", "gather", "amass", "concentrate", "pile up", "build up", "conglomerate", "stack up", "accrete", "mass", "drift", "lump", "ridge", "clump", "agglutinate", "bank" ] }, 
    "Power": { determination: "Competence",name: "Power", emoji: "👑", verbs: [ "enhance", "intensify", "deepen", "heighten", "consolidate", "reinforce", "broaden", "boost", "sharpen", "amplify", "expand", "redouble", "accentuate", "step up", "augment", "emphasize", "extend", "magnify", "lengthen", "beef up", "enforce", "accelerate", "maximize", "supplement", "enlarge", "amp up", "hasten", "quicken", "point up", "enliven", "reenforce", "restrengthen", "exacerbate", "stress", "aggravate", "jazz up" ] }, 
    "Design": { determination: "Autonomy",name: "Design", emoji: "🎨", verbs: [ "draw", "create", "generate", "bring", "cause", "produce", "do", "prompt", "work", "spawn", "induce", "make", "invoke", "yield", "effectuate", "effect", "catalyze", "bring about", "promote", "translate into", "introduce", "engender", "result in", "encourage", "found", "give rise to", "beget", "breed", "establish", "bring forth", "bring on", "initiate", "develop", "contribute to", "occasion", "determine", "inaugurate", "begin", "launch", "cultivate", "decide", "foster", "enact", "start", "pioneer", "render", "institute", "set", "set up", "forward", "conduce to", "further", "innovate", "nurture", "advance", "nourish", "turn out", "father" ] }, 
    "Story": { determination: "Autonomy",name: "Story", emoji: "📚", verbs: [ "undergo", "have", "endure", "see", "feel", "know", "witness", "taste", "suffer", "encounter", "sustain", "go through", "receive", "pass", "meet", "accept", "assimilate", "digest" ] }, 
    "Fantasy": { determination: "Autonomy",name: "Fantasy", emoji: "🧙", verbs: [ "imagine", "envision", "see", "dream", "visualize", "fantasy", "picture", "conceive", "envisage", "vision", "fancy", "contemplate", "feature", "daydream", "conjure up", "conceit", "image", "ideate",  "ponder", "reminisce", "reflect", "relive", "ruminate", "hallucinate", "muse", "foresee", "meditate", "invent", "concoct", "stargaze", "manufacture", "project", "re-create", "make up", "prefigure", "fabricate" ] }, 
    "Discovery": { determination: "Autonomy",name: "Discovery", emoji: "🏞️", verbs: [ "realize", "learn", "see", "hear", "find", "ascertain", "find out", "detect", "wise up", "catch on to", "get wind of", "encounter", "perceive", "hit on or upon", "spot", "figure out", "discern", "observe", "calculate", "espy", "tumble to", "divine", "note", "puzzle out", "mind", "dope out" ] } 
}
export default SOURCES_OF_MEANING